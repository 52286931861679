import { FC } from 'react';
import { IconButton } from '@cfa/react-components';
import styled from 'styled-components';
import {
  mediaQueries,
  themeColors,
  themeSizes,
} from '../../../styles/globalStyleVars';
import { AlertInterface } from '../../../interfaces/AlertInterface';
import { useAlertsStore } from '../../../stores/alertsStore';
import { AlertsBannerItem } from './AlertsBannerItem';
import { IconX } from '@tabler/icons-react';

export interface Props {
  alertsAndOutages: AlertInterface[];
  setAlertsDrawerOpen: () => void;
  setAlertChildCaseOpen: (val: AlertInterface) => void;
}

export interface SeeMoreProps {
  id: string;
  title: string;
}

const BannerContainer = styled.div`
  background-color: ${themeColors.notificationBackgroundColor};
  margin: 0;
  justify-content: center;
  display: flex;
  position: static;
  z-index: 5;
  top: 74px;

  ${mediaQueries.sm_up} {
    position: -webkit-sticky;
    position: sticky;
  }
`;

const BannerWrapper = styled.div`
  overflow-y: hidden;
  width: 100%;
  padding: ${themeSizes.md};
  ${mediaQueries.sm_up} {
    padding: ${themeSizes.md} 36px;
  }
  ${mediaQueries.md_up} {
    padding: ${themeSizes.md} ${themeSizes.xl};
  }
  ${mediaQueries.lg_up} {
    padding: ${themeSizes.md} 46px;
  }
`;

const AlertsWrapper = styled.div`
  > div:nth-child(2) {
    margin-top: ${themeSizes.md};
    ${mediaQueries.sm_up} {
      margin-top: ${themeSizes.xl};
    }
  }
`;

export const AlertsBanner: FC<Props> = ({
  alertsAndOutages,
  setAlertsDrawerOpen,
  setAlertChildCaseOpen,
}) => {
  const { setAllAlertsDismissed } = useAlertsStore();

  const handleCloseBanner = () => {
    setAllAlertsDismissed();
  };

  return (
    <BannerContainer data-testid='alerts-page-banner'>
      <BannerWrapper>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <IconButton
            onClick={() => handleCloseBanner()}
            style={{
              width: 'auto',
              padding: `${themeSizes.xs} 0`,
            }}
            data-testid='alerts-banner-close-btn'
          >
            <IconX color={themeColors.colorGray_6} />
          </IconButton>
        </div>
        <AlertsWrapper data-testid='alerts-banner-items'>
          {alertsAndOutages.map((alert, index) => (
            <AlertsBannerItem
              key={index}
              alert={alert}
              setAlertsDrawerOpen={setAlertsDrawerOpen}
              setAlertChildCaseOpen={setAlertChildCaseOpen}
            />
          ))}
        </AlertsWrapper>
      </BannerWrapper>
    </BannerContainer>
  );
};
