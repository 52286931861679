import { FC, useEffect } from 'react';
import { Typography, IconButton } from '@cfa/react-components';
import styled from 'styled-components';
import { themeColors, themeSizes } from '../../../styles/globalStyleVars';
import { useAlertsStore } from '../../../stores/alertsStore';
import { AlertInterface } from '../../../interfaces/AlertInterface';
import { callEvent } from '../../../utils/Amplitude/AmplitudeUtilities';
import { AlertsDrawerEmptyState } from './AlertsDrawerEmptyState';
import { AlertItem } from './AlertItem';
import FetchError from '../FetchError';
import { IconAlertTriangleFilled, IconX } from '@tabler/icons-react';
import { DashboardItem } from './DashboardItem';
import { useLocationStore } from '../../../stores/locationStore';

interface Props {
  alertsDrawerOpen: boolean;
  alertsError: boolean;
  alertsLoading: boolean;
  refetchAlerts: () => void;
  closeAlertsDrawer: () => void;
  setAlertChildCaseOpen: (val: AlertInterface | undefined) => void;
}

const AlertsDrawerHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: ${themeColors.secondaryBlue};
  margin-bottom: ${themeSizes.xl};

  > div {
    display: flex;

    > svg {
      margin-right: ${themeSizes.sm};
      align-self: center;
    }
  }

  > button {
    padding: 0;
  }
`;

const AlertsWrapper = styled.div`
  overflow: auto;
  height: calc(100% - 64px);
`;

export const AlertsDrawerContent: FC<Props> = ({
  closeAlertsDrawer,
  alertsDrawerOpen,
  alertsError,
  alertsLoading,
  refetchAlerts,
  setAlertChildCaseOpen,
}) => {
  const { activeLocation } = useLocationStore();
  const { alertsOutages, setAllAlertsDismissed } = useAlertsStore();

  const showEmptyState =
    alertsError || alertsLoading || alertsOutages.length === 0;

  const handleClose = () => {
    closeAlertsDrawer();
  };

  useEffect(() => {
    if (alertsDrawerOpen) {
      if (!alertsError && !alertsLoading) {
        setAllAlertsDismissed();
      }
      callEvent('view alerts', {});
    }
  }, [alertsDrawerOpen, alertsError, alertsLoading]);

  return (
    <div style={{ height: '100%' }} data-testid='alerts-drawer-content'>
      <AlertsDrawerHeader>
        <div data-testid='alerts-drawer-header'>
          <IconAlertTriangleFilled
            size={26}
            aria-label='Warning triangle icon'
          />
          <Typography variant='h3' color='secondary'>
            Alerts &amp; Outages
          </Typography>
        </div>
        <IconButton
          color='secondary'
          onClick={() => handleClose()}
          data-testid='alerts-close-button'
        >
          <IconX color={themeColors.colorGray_6} />
        </IconButton>
      </AlertsDrawerHeader>
      <>
        {showEmptyState ? (
          <>
            {alertsError ? (
              <div style={{ marginTop: '144px' }}>
                <FetchError
                  btnClick={() => refetchAlerts()}
                  errorType='modal-fetch'
                  page='alerts-drawer'
                />
              </div>
            ) : alertsLoading ? (
              <AlertsDrawerEmptyState
                heading='Working on that...'
                showLoadingSpinner
              />
            ) : (
              <>
                <AlertsDrawerEmptyState heading='There are no known alerts or outages.' />
                <DashboardItem activeLocation={activeLocation} />
              </>
            )}
          </>
        ) : (
          <AlertsWrapper className='alert-item' id='alerts-list-wrapper'>
            {alertsOutages.map((alert: AlertInterface, index: number) => {
              return (
                <AlertItem
                  alert={alert}
                  setAlertChildCaseOpen={setAlertChildCaseOpen}
                  alertsDrawerOpen={alertsDrawerOpen}
                  activeLocation={activeLocation}
                  showSummaryByDefault
                  key={index}
                />
              );
            })}
            <DashboardItem activeLocation={activeLocation} />
          </AlertsWrapper>
        )}
      </>
    </div>
  );
};
