import { FC, useEffect, createRef, useState, MouseEvent } from 'react';
import { Typography, Button, Divider } from '@cfa/react-components';
import styled from 'styled-components';
import { themeColors, themeSizes } from '../../../styles/globalStyleVars';
import {
  IconAlertTriangleFilled,
  IconBook2,
  IconChevronDown,
  IconChevronUp,
} from '@tabler/icons-react';
import RelativeTime from '../../RelativeTime/RelativeTime';
import { AffectsMeButton } from './AffectsMeButton';
import { AlertInterface } from '../../../interfaces/AlertInterface';
import { callEvent } from '../../../utils/Amplitude/AmplitudeUtilities';
import { TypographyLineLimit } from '../../Styled/TypographyElements';
import { RealTimeUpdate } from './RealTimeUpdate';

interface Props {
  alert: AlertInterface;
  setAlertChildCaseOpen?: (val: AlertInterface | undefined) => void;
  alertsDrawerOpen: boolean;
  activeLocation?: string;
  showSummaryByDefault?: boolean;
}

const AlertDiv = styled.div`
  background-color: ${themeColors.notificationBackgroundColor};
  border-radius: ${themeSizes.sm};
  width: 100%;
  margin-bottom: ${themeSizes.lg};
  padding: ${themeSizes.lg};
  display: flex;
  flex-direction: column;

  &:not(:has(~ .alert-item)) {
    margin-bottom: 18px;
  }
`;

const AlertStatus = styled.div`
  display: flex;
  justify-content: space-between;

  > div {
    color: ${themeColors.errorColor};
    display: flex;
    margin-bottom: 13px;

    > svg {
      margin-right: ${themeSizes.sm};
    }

    > p {
      color: ${themeColors.errorColor};
    }
  }
`;

const AlertSummaryText = styled(Typography)`
  color: ${themeColors.colorBlack};
  margin-top: 9px;
`;

const SeeMoreButton = styled(Button)`
  padding: 0;
  display: flex;
  display: -webkit-flex;
  justify-content: right;
  -webkit-justify-content: right;
  -webkit-align-self: flex-end;
`;

const LinkToURLButton = styled(Button)`
  padding: 0;
  min-width: auto;
  margin-top: ${themeSizes.sm};

  > svg {
    margin-right: ${themeSizes.sm};
  }
`;

export const AlertItem: FC<Props> = ({
  alert,
  setAlertChildCaseOpen,
  alertsDrawerOpen,
  activeLocation,
  showSummaryByDefault,
}) => {
  const [overflowingSummary, setOverflowingSummary] = useState<boolean>(false);
  const [expandedSummary, setExpandedSummary] = useState<boolean>(false);
  const summaryRef = createRef<HTMLParagraphElement>();

  useEffect(() => {
    if (alertsDrawerOpen) {
      if (summaryRef && summaryRef.current) {
        if (
          summaryRef?.current?.clientHeight &&
          summaryRef?.current?.clientHeight > 240
        ) {
          setOverflowingSummary(true);
        }
      }
    } else {
      //wait until after drawer closes (225 ms animation is done) to clear out state
      const timeout = setTimeout(() => {
        setOverflowingSummary(false);
        setExpandedSummary(false);
      }, 225);

      return () => clearTimeout(timeout);
    }
    return;
  }, [alertsDrawerOpen]);

  const handleExpandSummary = (
    e: MouseEvent<HTMLButtonElement>,
    alertTitle: string,
    alertId: string
  ) => {
    e.preventDefault();
    if (expandedSummary) {
      setExpandedSummary(false);
    } else {
      setExpandedSummary(true);
      callEvent('expand alert', {
        'alert id': alertId,
        title: alertTitle,
      });
    }
  };

  const handleAffectsMeClick = () => {
    if (setAlertChildCaseOpen) {
      setAlertChildCaseOpen(alert);
    }
    callEvent('select this affects my restaurant', {
      'alert id': alert.id,
      id: alert.majorCaseId as string,
      location: 'drawer',
    });
  };

  return (
    <AlertDiv key={alert.id} className='alert-item'>
      <AlertStatus>
        <div>
          <IconAlertTriangleFilled
            size={26}
            aria-label='Warning triangle icon'
          />
        </div>
        <Typography data-testid='alert-time'>
          Updated: <RelativeTime date={alert.updatedOn} />
        </Typography>
      </AlertStatus>
      <TypographyLineLimit
        $fontColor={themeColors.colorBlack}
        $lineLimit={3}
        variant='h4'
        style={{ maxHeight: '96px' }}
        data-testid='alert-heading'
      >
        {alert.title}
      </TypographyLineLimit>

      {showSummaryByDefault ? (
        <>
          {overflowingSummary && !expandedSummary ? (
            <TypographyLineLimit
              $fontColor={themeColors.colorBlack}
              $lineLimit={10}
              style={{ maxHeight: '240px', marginTop: '9px' }}
              data-testid='alert-summary'
            >
              {alert.summary}
            </TypographyLineLimit>
          ) : (
            <AlertSummaryText ref={summaryRef} data-testid='alert-summary'>
              {alert.summary}
            </AlertSummaryText>
          )}

          {overflowingSummary && (
            <SeeMoreButton
              variant='text'
              color='secondary'
              size='sm'
              onClick={(e: MouseEvent<HTMLButtonElement>) =>
                handleExpandSummary(e, alert.title, alert.id)
              }
            >
              {expandedSummary ? 'See Less' : 'See More'}
            </SeeMoreButton>
          )}
          {(alert.majorCaseId || alert.url || alert.outagePageURL) && (
            <Divider
              variant='fullLength'
              style={{
                margin: `13px 0 ${alert.outagePageURL ? '6px' : themeSizes.md}`,
              }}
            />
          )}
          {alert.outagePageURL && (
            <RealTimeUpdate
              alert={alert}
              activeLocation={activeLocation ?? ''}
            />
          )}
          {alert.majorCaseId && (
            <AffectsMeButton onClick={() => handleAffectsMeClick()} />
          )}
          {alert.url && (
            <LinkToURLButton
              variant='text'
              color='secondary'
              href={alert.url}
              target='_blank'
              onClick={() =>
                callEvent('alert link', {
                  'alert id': alert?.id,
                  title: alert.title,
                })
              }
            >
              <IconBook2 size={16} />
              <Typography color='secondary'>Read More</Typography>
            </LinkToURLButton>
          )}
        </>
      ) : (
        alert.summary && (
          <>
            <SeeMoreButton
              variant='text'
              color='secondary'
              size='sm'
              onClick={(e: MouseEvent<HTMLButtonElement>) =>
                handleExpandSummary(e, alert.title, alert.id)
              }
            >
              {expandedSummary ? 'Hide' : 'Show'} Details{' '}
              {expandedSummary ? (
                <IconChevronUp
                  size={themeSizes.lg}
                  style={{ margin: `0 0 0 ${themeSizes.xs}` }}
                />
              ) : (
                <IconChevronDown
                  size={themeSizes.lg}
                  style={{ margin: `0 0 0 ${themeSizes.xs}` }}
                />
              )}
            </SeeMoreButton>
            {expandedSummary && (
              <AlertSummaryText data-testid='alert-summary'>
                {alert.summary}
              </AlertSummaryText>
            )}
          </>
        )
      )}
    </AlertDiv>
  );
};
