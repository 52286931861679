import { FC, useEffect, useState } from 'react';
import {
  Button,
  LoadingIndicator,
  ModalBody,
  TextField,
  Typography,
} from '@cfa/react-components';
import styled from 'styled-components';
import { themeColors, themeSizes } from '../../../../styles/globalStyleVars';
import { ButtonRow } from '../../../Styled/ButtonRow';
import { callEvent } from '../../../../utils/Amplitude/AmplitudeUtilities';
import { useLocationStore } from '../../../../stores/locationStore';
import { useHistory } from 'react-router-dom';

const RedirectLoader = styled.div`
  display: flex;
  align-items: center;
  padding: ${themeSizes.xl} 0 ${themeSizes.lg} 0;
  flex-direction: column;

  h4 {
    color: ${themeColors.colorGray_6} !important;
    margin-top: ${themeSizes.md};
  }

  .cfa-loading-indicator {
    svg {
      height: 28px;
    }

    .cfa-loading-logo {
      left: 21%;
      top: 22%;
    }

    .cfa-loading-circlegraph-page-sm {
      height: ${themeSizes.xxl};
      width: ${themeSizes.xxl};

      .cfa-loading-dot {
        transform-origin: ${themeSizes.lg} 0;
      }
    }
  }
`;

const WebsiteFeedbackModalSubtitle: FC<{
  text: JSX.Element;
  alignment: 'inherit' | 'left' | 'center' | 'right' | 'justify';
}> = ({ text, alignment }) => (
  <Typography
    align={alignment}
    style={{
      color: themeColors.colorGray_8,
      marginBottom: themeSizes.md,
    }}
  >
    {text}
  </Typography>
);

interface Props {
  feedbackSuccess: boolean;
  submitFeedbackLoading: boolean;
  isExtraSmallScreen: boolean;
  setFeedbackComment: (comment: string) => void;
  feedbackComment: string;
  onClose: () => void;
  subTitle: JSX.Element;
  screenName: string;
  inputPlaceholder: string;
  redirectToHelpOnline?: () => void;
  feedbackTicketID?: string;
  setFeedbackSuccess?: () => void;
  submitFeedback: () => void;
  hideTicketButton?: boolean;
  closeButtonText?: string;
  whatsNewFeedback?: boolean;
  featureFlowFeedback?: boolean;
}

const WebsiteFeedbackModalBody: FC<Props> = ({
  feedbackSuccess,
  submitFeedbackLoading,
  isExtraSmallScreen,
  setFeedbackComment,
  feedbackComment,
  onClose,
  subTitle,
  screenName,
  inputPlaceholder,
  redirectToHelpOnline,
  feedbackTicketID,
  setFeedbackSuccess,
  submitFeedback,
  hideTicketButton,
  closeButtonText,
  whatsNewFeedback,
  featureFlowFeedback,
}) => {
  const { activeLocation } = useLocationStore();
  const history = useHistory();
  const [amplitudeFeedbackLoading, setAmplitudeFeedbackLoading] =
    useState<boolean>(false);

  const callFeedbackEvent = () => {
    callEvent('support feedback', {
      locationNum: activeLocation,
      screenName: screenName,
      feedbackComment: feedbackComment,
    });
  };

  useEffect(() => {
    if (!amplitudeFeedbackLoading) return;

    callFeedbackEvent();
    setTimeout(() => {
      setFeedbackSuccess?.();
    }, 2000);
  }, [amplitudeFeedbackLoading]);

  function handleSubmitFeedback() {
    !submitFeedbackLoading && feedbackSuccess && setFeedbackComment('');
    if (['Profile', 'Footer'].includes(screenName)) {
      return submitFeedback();
    }
    if (
      [
        'Introduction',
        'Equipment Feature',
        'Search',
        'Ticket Details',
      ].includes(screenName)
    ) {
      return setAmplitudeFeedbackLoading(true);
    }
    return callFeedbackEvent();
  }

  return (
    <ModalBody>
      {!feedbackSuccess ? (
        <WebsiteFeedbackModalSubtitle
          text={subTitle}
          alignment={
            isExtraSmallScreen && redirectToHelpOnline ? 'center' : 'left'
          }
        />
      ) : (
        <WebsiteFeedbackModalSubtitle
          text={<>Everything you shared will help us better support you.</>}
          alignment='center'
        />
      )}
      {!feedbackSuccess ? (
        <>
          <TextField
            placeholder={inputPlaceholder}
            onChange={e => setFeedbackComment(e.target.value)}
            multiline
            fullWidth
            rows={5}
            disabled={submitFeedbackLoading || amplitudeFeedbackLoading}
            data-testid='feedback-comment-field'
          />
          <ButtonRow
            style={{
              paddingTop: themeSizes.xxl,
              flexDirection: redirectToHelpOnline ? 'column' : 'row',
              rowGap: redirectToHelpOnline ? themeSizes.lg : '12px',
            }}
            rowAlign='center'
          >
            {redirectToHelpOnline ? (
              <>
                <Button
                  size='sm'
                  disabled={
                    !feedbackComment?.trim().length || amplitudeFeedbackLoading
                  }
                  loading={amplitudeFeedbackLoading}
                  style={{ padding: `1px ${themeSizes.xl}` }}
                  onClick={() => {
                    setAmplitudeFeedbackLoading(true);
                  }}
                >
                  Submit and go to HELP Online
                </Button>
                <Button
                  variant='outlined'
                  color='secondary'
                  style={{ minWidth: '267px' }}
                  size='sm'
                  disabled={amplitudeFeedbackLoading}
                  onClick={() => {
                    redirectToHelpOnline && redirectToHelpOnline();
                  }}
                >
                  Skip and go to HELP Online
                </Button>
              </>
            ) : (
              <>
                <Button
                  size='sm'
                  variant='outlined'
                  onClick={onClose}
                  disabled={submitFeedbackLoading || amplitudeFeedbackLoading}
                >
                  Cancel
                </Button>
                <Button
                  size='sm'
                  disabled={!feedbackComment?.trim().length}
                  loading={submitFeedbackLoading || amplitudeFeedbackLoading}
                  onClick={() => handleSubmitFeedback()}
                >
                  {!isExtraSmallScreen ? 'Submit Feedback' : 'Submit'}
                </Button>
              </>
            )}
          </ButtonRow>
        </>
      ) : (
        <>
          {redirectToHelpOnline ? (
            <RedirectLoader>
              <LoadingIndicator variant='page' size='sm' />
              <Typography variant='h4'>
                Redirecting you to Help Online.
              </Typography>
            </RedirectLoader>
          ) : (
            <ButtonRow
              style={{ paddingTop: themeSizes.xl }}
              rowAlign='center'
              stack
            >
              {!hideTicketButton && (
                <Button
                  color='secondary'
                  size='sm'
                  variant='outlined'
                  onClick={() => {
                    onClose();
                    history.push(
                      `/tickets/${activeLocation}/${feedbackTicketID}`
                    );
                  }}
                >
                  Go to Feedback Ticket
                </Button>
              )}
              <Button
                color='secondary'
                size='sm'
                variant={
                  whatsNewFeedback || featureFlowFeedback ? 'outlined' : 'text'
                }
                onClick={onClose}
              >
                {closeButtonText}
              </Button>
            </ButtonRow>
          )}
        </>
      )}
    </ModalBody>
  );
};

export default WebsiteFeedbackModalBody;
