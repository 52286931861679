import { IconExternalLink } from '@tabler/icons-react';
import { Button, Typography } from '@cfa/react-components';
import { FC } from 'react';
import styled from 'styled-components';
import { themeColors, themeSizes } from '../../../styles/globalStyleVars';
import { callEvent } from '../../../utils/Amplitude/AmplitudeUtilities';

interface Props {
  activeLocation: string;
}

const DashboardDiv = styled.div`
  border: 1px solid ${themeColors.colorGray_1};
  border-radius: ${themeSizes.sm};
  width: 100%;
  padding: ${themeSizes.lg};
  display: flex;
  flex-direction: column;
  margin-bottom: 56px;
  cursor: pointer;

  &:hover {
    outline-color: ${themeColors.colorGray_3};
    border-color: ${themeColors.colorGray_3};
    transition: hover 0.2s ease;
  }
`;

const DashboardButton = styled(Button)`
  display: flex;
  justify-content: left;
  margin-top: ${themeSizes.xs};
  padding: 0;
`;

export const DashboardItem: FC<Props> = ({ activeLocation }) => {
  return (
    <a
      href='https://cfa-customer-digital.statuspage.io/'
      target='_blank'
      onClick={() =>
        callEvent('launch digital performance dashboard', {
          locationNum: activeLocation,
        })
      }
      style={{ textDecoration: 'none' }}
      rel='noreferrer'
      id='performance-dashboard-link'
      aria-describedby='alert-dashboard-button'
    >
      <DashboardDiv>
        <Typography
          fontWeight='bold'
          style={{ color: themeColors.colorGray_8 }}
        >
          Digital Performance Dashboard
        </Typography>
        <Typography
          style={{
            color: themeColors.colorGray_7,
            paddingTop: themeSizes.sm,
          }}
        >
          See chain-wide outage and maintenance updates in real-time for CFA
          digital ordering, payments, and third-party delivery
        </Typography>
        <DashboardButton
          variant='text'
          color='secondary'
          id='alert-dashboard-button'
        >
          <IconExternalLink
            size={20}
            style={{ marginRight: themeSizes.sm }}
            aria-label='external-link-icon'
          />
          View Dashboard
        </DashboardButton>
      </DashboardDiv>
    </a>
  );
};
