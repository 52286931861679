import { ChangeEvent, FC, useEffect } from 'react';
import { Typography, Checkbox } from '@cfa/react-components';
import styled from 'styled-components';
import { Location } from '../../../interfaces/LocationInterface';
import { themeSizes } from '../../../styles/globalStyleVars';
import { useSubmitFormToCreateCase } from '../../../utils/api/requests/useMutations';
import { SettledTicket } from './ChildCaseDrawerContent';

interface Props {
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  location: Location;
  submitData: boolean;
  majorCaseId: string;
  userGuid: string;
  settledChildTickets: SettledTicket[];
  setSettledChildTickets: (val: SettledTicket[]) => void;
}

const LocationCheckbox = styled(Checkbox)`
  margin-left: ${themeSizes.xs};

  label {
    font-weight: 500;
  }
`;

export const ChildCaseCheckbox: FC<Props> = ({
  onChange,
  location,
  submitData,
  majorCaseId,
  userGuid,
  settledChildTickets,
  setSettledChildTickets,
}) => {
  const {
    mutate: submitChildCase,
    error: childCaseError,
    isSuccess: childCaseSuccess,
    data: childCaseData,
  } = useSubmitFormToCreateCase('major_issue_assoc', false);

  useEffect(() => {
    if (submitData) {
      const childCaseData = {
        locationNum: location.number,
        variables: [
          { id: 'location_num', value: location.number },
          { id: 'case_contact_guid', value: userGuid },
          { id: 'parent_case', value: majorCaseId },
        ],
        formTitle: 'Major Issue Associated',
      };
      submitChildCase(childCaseData);
    }
  }, [submitData]);

  useEffect(() => {
    if (childCaseSuccess || childCaseError) {
      const ticketInfo = {
        id: childCaseData?.id,
        locationNum: location.number,
        error: !!childCaseError,
      };
      setSettledChildTickets([...settledChildTickets, ticketInfo]);
    }
  }, [childCaseSuccess, childCaseError]);

  return (
    <div key={location.id} data-testid='location-updates-checkbox'>
      <LocationCheckbox
        value={location.name}
        label={location.name}
        onChange={onChange}
      />
      <Typography
        variant='body2'
        style={{ margin: `${themeSizes.xs} 0 0 36px` }}
      >
        {location.number}
      </Typography>
    </div>
  );
};
